import(/* webpackMode: "eager" */ "/var/www/vhosts/codead.net/parsanalitik.com/app/components/ChatBox/Chatbox.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/app/components/Header/Header.jsx");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/codead.net/parsanalitik.com/app/components/Page/FooterPage.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/app/components/Page/FooterPageObjected.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/app/components/ScrollToTopButton.jsx");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/codead.net/parsanalitik.com/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/@mui/icons-material/esm/CategoryOutlined.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/@mui/icons-material/esm/ContactEmergencyOutlined.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/@mui/icons-material/esm/ContactsOutlined.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/@mui/icons-material/esm/ContactSupportOutlined.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/@mui/icons-material/esm/DashboardOutlined.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/@mui/icons-material/esm/Facebook.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/@mui/icons-material/esm/Instagram.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/@mui/icons-material/esm/LinkedIn.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/@mui/icons-material/esm/LiveHelpOutlined.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/@mui/icons-material/esm/PsychologyOutlined.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/@mui/icons-material/esm/SellOutlined.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/@mui/icons-material/esm/TaskOutlined.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/@mui/icons-material/X.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/@mui/material/IconButton/index.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/codead.net/parsanalitik.com/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
